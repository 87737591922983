export const environment = {
    production: false,
    configURL: "https://edi-configuration.qa.covisint.com/edisearch/api",
    searchURL: "https://edi-search.qa.covisint.com/edisearch/api",
    userURL: "https://edi-user.qa.covisint.com/edisearch/api",
	securityURL: "https://edi-security.qa.covisint.com/edisearch/api",
    supplyPowerURL:'https://gmsupplypower.qa.covisint.com/gmsp_en_US',
    dashBoardCommunityId:1,
    redirectURL: "https://sso.qa.covisint.com",
    communityName:'Opentext'
  };
  